<template>
  <div class="card">
    <div class="header-text">{{ title }}</div>

    <div class="chart">
      <vc-donut :sections="sections">
        <div class="chartText">
          <span v-if="labels.length">{{ labels[1][1] }}</span>
          <span v-if="labels.length < 0">0</span>
          <p class="m-0">NFS-e restantes</p>
        </div>
      </vc-donut>
    </div>

    <hr />

    <div class="legends">
      <ul>
        <li v-for="(label, index) in labels" :key="label[0]">
          <div
            class="chartValueComponent"
            :style="cssVars"
            v-bind:class="`color-${index}`"
          >
            <span>{{ label[1] }}</span>
          </div>

          <div class="chartLabelTitle">
            <span class="dot" :style="cssVars" :class="`color-${index}`"></span>
            <p>{{ label[0] }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'
import { getCurrentClinic } from '@/utils/localStorageManager'

Vue.use(Donut)

export default {
  props: {
    title: { type: String, required: true },
    colors: { type: [Array], required: true },
    dashboard: Object
  },
  data() {
    return {
      labels: [],
      sections: [],
      clinic: getCurrentClinic()
    }
  },
  mounted() {
    try {
      this.labels = [
        ['Emitidos', Number(this.dashboard.cards.notes_issued)],
        [
          'Restantes',
          Number(
            this.dashboard.contract.notes_per_period -
              this.dashboard.cards.notes_issued
          )
        ],
        ['Canceladas', Number(this.dashboard.cards.canceled_notes)]
      ]
      this.sections = this.sectionsConstruct()
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    sectionsConstruct() {
      const chartLabels = []
      let sumLabels = 0
      this.labels.map(label => {
        sumLabels += label[1]
      })

      for (let i = 0; i < this.labels.length; i++) {
        chartLabels.push({
          label: this.labels[i][0],
          value: (this.labels[i][1] / sumLabels) * 100,
          color: this.colors[i]
        })
      }
      return chartLabels
    }
  },

  computed: {
    cssVars() {
      return {
        '--color-0': this.colors[0],
        '--color-1': this.colors[1],
        '--color-2': this.colors[2],
        '--color-3': this.colors[3]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chartText {
  span {
    font-size: 24px;
    font-weight: 500;
    color: var(--type-active);
    font-family: 'Red Hat Display';
  }

  p {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Nunito Sans';
  }
}

hr {
  margin: 0 auto !important;
  width: 80%;
}

.chart {
  margin-bottom: 2%;
}

.card {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  text-align: left;
  position: relative;
  justify-content: center;
}

.header-text {
  margin: 4% 8%;
  font-size: 18px;
  font-weight: 600;
  color: var(--dark-blue);
}

.chartLabelTitle p {
  font-size: 10pt;
  margin-top: 5%;
}

.legends {
  margin-top: 2%;
  align-items: left;
  justify-content: center;
  color: var(--dark-blue);
  font-size: 14px;
}

.legends ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style: none;
}

.legends li {
  margin: 4% 5%;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  margin-right: 5px;
  margin-top: 3px;
}

.legends li .chartValueComponent {
  height: 40px;
  opacity: 0.7;
  max-width: 40px;
  font-weight: 600;
  border-radius: 16px;
  color: var(--dark-blue);

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 16px;
    opacity: 1 !important;
    color: var(--dark-blue);
    vertical-align: text-top;
  }
}

.color-0 {
  background-color: var(--color-0);
}

.color-1 {
  background-color: var(--color-1);
}

.color-2 {
  background-color: var(--color-2);
}

.color-3 {
  background-color: var(--color-3);
}

.empty-state {
  width: 100%;
  height: 100%;
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  color: var(--type-placeholder);

  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff99;
}

@media (max-width: 1440px) {
  .header-text {
    margin: 5% 5%;
    font-size: 15px;
  }
}
</style>
